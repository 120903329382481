import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LetoPoster from '../../images/binders/nobg/Leto.png'
import UnityPoster from '../../images/binders/nobg/Unity.png'
import { H2, StyledLearnMore } from '../../components'

export const PosterSection = () => {
  const { t } = useTranslation()
  return (
    <section className="poster">
      <div className="poster-imgWrapper__unity"></div>
      <div className="poster-imgWrapper__leto"></div>

      <div className="poster-shortWidth">
        <div className="poster-group__unity">
          <H2>{t('findCb')}</H2>
          <p className="poster-p">
            {t('poster1')}
            <br />
            <br />
            {t('poster2')}
            <br />
            <br />
            {t('getLucky')}
          </p>
          <CenteredLearnMore to="/chainbinders">
            {t('viewChainbinders')}
          </CenteredLearnMore>
        </div>

        <img src={UnityPoster} alt="" className="unity-mobile-poster" />

        <div className="poster-group__leto">
          <H2>{t('completeCollect')}</H2>
          <p className="poster-p">
            {t('posterLeto1')}
            <br />
            <br />
            {t('posterLeto2')}
            <br />
            <br />
            {t('posterLeto3')}
            <br />
            <br />
            {t('posterLeto4')}
          </p>
          <LinkOut
            href="https://dokidoki.com/play-gacha"
            rel="noreferrer"
            target="_blank"
          >
            {t('rollBinder')}
          </LinkOut>
        </div>
        <img src={LetoPoster} alt="" className="unity-mobile-poster" />
      </div>
    </section>
  )
}

const CenteredLearnMore = styled(StyledLearnMore)`
  justify-self: center;
  margin-top: 2rem;
`

const LinkOut = styled.a`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  /* width: 10vw; */
  padding-right: 2rem;
  padding-left: 2rem;
  text-align: center;
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  justify-self: center;
  margin-top: 2rem;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  border-radius: 36px;
  border: 2px solid white;

  transition: 0.6s;
  overflow: hidden;
  &:focus {
    outline: 0;
  }
  &:hover {
    background: rgb(255, 255, 255);
    color: black;

    cursor: pointer;
  }
`
