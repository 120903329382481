import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LGEGraph from '../../assets/ENG/new-liquid-pool.svg'
import TokenCurve from '../../assets/ENG/new-token-curve.svg'
import { H3, StyledLearnMore } from '../../components'
import Background from '../../images/space-lines.png'

export const LGE = () => {
  const { t } = useTranslation()

  return (
    <StyledBackground>
      <StyledSectionShortWidth>
        <ChartContainer>
          <ChartFlexGroup>
            <StyledH3>{t('Fixed Token Price Curve')}</StyledH3>
            <ImgWrapper>
              <StyledImg src={TokenCurve} alt="LGE distribution chart" />
            </ImgWrapper>

            <StyledP>
              {t('lge1')}
              <br />
              <br />
              {t('lgeNoLimit')}
            </StyledP>
            <PositionedLink to="/token">{t('learnMore')}</PositionedLink>
          </ChartFlexGroup>

          <ChartFlexGroup>
            <StyledH3>{t('Liquidity Pool')}</StyledH3>

            <ImgWrapper>
              <StyledImg src={LGEGraph} alt="LGE distribution chart" />
            </ImgWrapper>

            <StyledP>{t('lge2')}</StyledP>
            <PositionedLink to="/token">{t('learnMore')}</PositionedLink>
          </ChartFlexGroup>
        </ChartContainer>
      </StyledSectionShortWidth>
    </StyledBackground>
  )
}

const StyledH3 = styled(H3)`
  margin-top: 0;
`

const StyledBackground = styled.section`
  background: url(${Background}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
`
const PositionedLink = styled(StyledLearnMore)`
  justify-self: center;
  align-self: end;
  margin-bottom: 0;
`
const StyledSectionShortWidth = styled.section`
  width: 85%;
  position: relative;
  margin-bottom: 5rem;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 200px) {
    margin-top: 3rem;
  }
  @media (min-width: 750px) {
    padding-top: 2rem;
  }
  @media (min-width: 1200px) {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
  }
`

const StyledP = styled.p`
  color: #ffffff;
  align-self: flex-start;

  @media (min-width: 200px) {
  }

  @media (min-width: 1200px) {
  }
`

const StyledImg = styled.img`
  width: 80%;
`

const ImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CenterItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 1rem;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  > h2 {
    margin-top: 0;
  }
`

const ChartContainer = styled.div`
  display: grid;
  grid-column-gap: 2rem;
  justify-items: center;
  height: 100%;
  margin-top: 3rem;
  row-gap: 3rem;
  column-gap: 3rem;

  @media (min-width: 200px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 750px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
`

const ChartFlexGroup = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: min-content 150px auto min-content;
  row-gap: 3rem;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  @media (min-width: 200px) {
    padding: 1rem;
  }

  @media (min-width: 750px) {
    padding: 2rem;
  }

  @media (min-width: 1200px) {
    padding: 2rem;
    height: 100%;
    grid-template-rows: min-content 250px auto min-content;
  }
`
