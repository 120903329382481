import { Link } from 'gatsby'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { GlassCard } from '../../components'
import BNDIcon from '../../images/bnd-token-circle.svg'
import ExitIcon from '../../images/exit-modal_outlined.svg'
import Background from '../../images/hero-bg.svg'

export const LandingSection = () => {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()

  const handleModalClick = () => {
    setShowModal(!showModal)
  }

  const handleExitModalClick = () => {
    setShowModal(false)
  }

  return (
    <BackgroundSection>
      <div className="mobile-landing">
        <HeroH1>{t('takeOath')}</HeroH1>

        <div className="home-video__center">
          <div className="iframe-container">
            <iframe
              title="Chainbinders Character Trailer 1"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/-_7qz7_o_2I"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <HeroButton
          href="https://dokidoki.com/gachapon/0xeFd7b1DC887b79568A59c39e6d35B6F2F2D18f02"
          target="_blank"
          rel="noreferrer"
        >
          {t('rollBinder')}
        </HeroButton>
      </div>

      <StyledSectionShortWidth>
        <GlassCard>
          <HeroH1>{t('takeOath')}</HeroH1>

          <div className="home-video__center">
            <div className="iframe-container">
              <iframe
                title="Chainbinders Character Trailer 1"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/-_7qz7_o_2I"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="hero-card">
            <HeroButton
              href="https://dokidoki.com/gachapon/0xeFd7b1DC887b79568A59c39e6d35B6F2F2D18f02"
              target="_blank"
              rel="noreferrer"
            >
              {t('rollBinder')}
            </HeroButton>
          </div>
        </GlassCard>
      </StyledSectionShortWidth>
    </BackgroundSection>
  )
}

const CloseButton = styled.button`
  border: 1px solid white;
  padding: 0.5rem 1rem;
  border-radius: 18px;

  &:hover {
    background: rgb(255, 255, 255);
    color: black;

    cursor: pointer;
  }
`

const ExitButton = styled.button`
  width: 2rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
`

const ModalCardBorder = styled.div`
  border: 2px solid #00ffc2;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;

  width: 100%;
  height: 100%;

  color: white;
`

const StyledPopUp = styled.div`
  z-index: 1001;
  background: black;
  display: flex;
  justify-content: center;

  padding: 1rem;
  line-height: 150%;
  z-index: 80;

  position: absolute;

  @media (min-width: 200px) {
    width: 100%;
    height: 26rem;
  }

  @media (min-width: 750px) {
    height: 20rem;
    width: 40rem;
  }
`

const ButtonGroup = styled.div`
  display: flex;
`

const HeroH1 = styled.h1`
  color: white;
  font-family: 'Roboto';
  font-weight: 400;

  text-align: center;
  margin-right: 1rem;
  margin-left: 1rem;
`

const HeroOutlineButton = styled(Link)`
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-right: 2rem;
  padding-left: 2rem;
  border-radius: 18px;
  font-weight: 600;
  font-family: 'Open Sans';
  font-size: 1.25rem;
  box-sizing: border-box;

  z-index: 1;
  letter-spacing: 1px;
  text-decoration: none;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(15px);
  border-radius: 3.5rem / 100%;
  border: 2px solid #00ffc2;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  color: rgba(255, 255, 255, 0.8);

  margin-left: 2rem;

  @media (min-width: 1200px) {
    padding-right: 4rem;
    padding-left: 4rem;
  }
`

const HeroButton = styled.a`
  color: black;
  line-height: 150%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  border-radius: 18px;
  font-weight: 600;
  font-family: 'Open Sans';
  font-size: 1.25rem;
  box-sizing: border-box;
  z-index: 1;
  letter-spacing: 1px;
  text-decoration: none;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(15px);
  border-radius: 3.5rem / 100%;

  background: rgb(0, 255, 194);
  background: linear-gradient(
    90deg,
    rgba(0, 255, 194, 1) 0%,
    rgba(0, 234, 223, 1) 100%
  );

  &:hover {
    box-shadow: 0 0 5px #00ffc2, 0 0 15px #00ffc2, 0 0 30px #00ffc2,
      0 0 60px #00ffc2;
  }

  @media (min-width: 1200px) {
    padding-right: 5rem;
    padding-left: 5rem;
  }
`

const BackgroundSection = styled.section`
  position: relative;

  padding-top: 5rem;
  background: url(${Background}) no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 5rem;

  position: relative;
`

const StyledSectionShortWidth = styled.section`
  padding-top: 3rem;
  @media (min-width: 200px) {
    display: none;
  }
  @media (min-width: 750px) {
    width: 85%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 1200px) {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
  }
`

const StyledH5 = styled.button`
  color: ${(props) => props.color};
  font-size: 1rem;
  margin-top: 10px;
  font-weight: 300;
  @media (min-width: 200px) {
    margin-bottom: 1rem;
  }

  @media (min-width: 750px) {
    margin-bottom: 0;
  }
`
