import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const BigTextSection = () => {
  const { t } = useTranslation()
  return (
    <FullWidthSection>
      <ShortWidthBorder>
        <p className="bigText-text">{t('bigText1')}</p>
        <div className="bigText-group">
          <p className="bigText-regular">
            {t('bigText2')}
            <br />
            <br />
            {t('bigText3')}
          </p>
        </div>
      </ShortWidthBorder>
    </FullWidthSection>
  )
}

const ShortWidthBorder = styled.div`
  position: relative;
  border: 4px solid #ff9900;
  padding: 2rem;
  padding-bottom: 2rem;
  @media (min-width: 200px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  @media (min-width: 750px) {
    padding-top: 5rem;
    padding-bottom: 5rem;
    width: 90%;
  }
  @media (min-width: 1200px) {
    flex-direction: row;
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 64px;
    padding-left: 64px;
  }
`

const FullWidthSection = styled.section`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: contain;
  overflow-x: hidden;

  background: black;
  padding: 2rem;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
`
