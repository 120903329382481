import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import CardRarity from '../../assets/ENG/rarity-chance.svg'
import { H2 } from '../../components'

export const RaritySection = () => {
  const { t } = useTranslation()
  return (
    <section className="last-section">
      <div className="last-shortWidth">
        <CenteredH2>{t('forgeDestroy')}</CenteredH2>

        <div className="last-group">
          <div className="last-text">
            <p>
              {t('rarity1')}
              <br />
              <br />
              {t('rarity2')}
              <br />
              <br />
              {t('rarity3')}
              <br />
              <br />
              {t('rarity4')}
            </p>
          </div>

          <div className="img-wrapper">
            <img src={CardRarity} alt="" className="chart" />
          </div>
        </div>
      </div>
    </section>
  )
}

const CenteredH2 = styled(H2)`
  text-align: center;
  margin-top: 1rem;
`
