import React from 'react'
import styled from 'styled-components'
import { BigTextSection } from '../../features/home/BigTextSection'
import { FourCards } from '../../features/home/FourCards'
import { LandingSection } from '../../features/home/LandingSection'
import { LGE } from '../../features/home/LGE'
import { PosterSection } from '../../features/home/PosterSection'
import { RaritySection } from '../../features/home/RaritySection'
import './Home.scss'

export const Home: React.FC = () => {
  return (
    <MainHomeWrapper>
      <LandingSection />
      <FourCards />
      <LGE />
      <BigTextSection />
      <PosterSection />
      <RaritySection />
    </MainHomeWrapper>
  )
}
export default Home

const MainHomeWrapper = styled.section`
  display: grid;
  grid-template-rows: auto;
  row-gap: 0;
  height: 100%;
  width: 100vw;
  justify-items: center;
  align-items: center;

  @media (min-width: 200px) {
  }

  @media (min-width: 750px) {
  }

  @media (min-width: 1200px) {
  }
`
