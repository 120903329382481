import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { StyledLearnMore } from '../../components'
import DeflationaryIcon from '../../images/diamond.svg'
import ValueIcon from '../../images/increase-value.svg'
import LiquidiqtyIcon from '../../images/liquid.svg'
import LowGasIcon from '../../images/low-cost.svg'

export const FourCards = () => {
  const { t } = useTranslation()
  return (
    <BackgroundSection>
      <StyledSectionShortWidth>
        <CardContainer>
          <CardGroupsOf2>
            <CardBackground color="#FF9900">
              <IconWrapper>
                <img src={ValueIcon} alt="" />
              </IconWrapper>
              <StyledH2>{t('nftIncrease')}</StyledH2>
              <StyledCardP>{t('nftRedeem')}</StyledCardP>

              <LearnMore to="/game-theory">{t('learnMore')}</LearnMore>
            </CardBackground>

            <CardBackground color="#00FFC2">
              <IconWrapper>
                <img src={LowGasIcon} alt="" />
              </IconWrapper>
              <StyledH2>{t('lowGas')}</StyledH2>
              <StyledCardP>{t('funMatic')}</StyledCardP>

              <LinkOut
                href="https://dokidoki.com/"
                rel="noreferrer"
                target="_blank"
              >
                {t('dokiRef')}
              </LinkOut>
            </CardBackground>
          </CardGroupsOf2>

          <CardGroupsOf2>
            <CardBackground color="#00D1FF">
              <IconWrapper>
                <img src={DeflationaryIcon} alt="" />
              </IconWrapper>
              <StyledH2>{t('deflationary')}</StyledH2>
              <StyledCardP>{t('bndExclusive')}</StyledCardP>
            </CardBackground>

            <CardBackground color="#C173FF">
              <IconWrapper>
                <img src={LiquidiqtyIcon} alt="" />
              </IconWrapper>
              <StyledH2>{t('worldFirst')}</StyledH2>
              <StyledCardP>{t('noWaitBurn')}</StyledCardP>

              <LearnMore to="/game-theory">{t('learnMore')}</LearnMore>
            </CardBackground>
          </CardGroupsOf2>
        </CardContainer>
      </StyledSectionShortWidth>
    </BackgroundSection>
  )
}

const LinkOut = styled.a`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  /* width: 10vw; */
  padding-right: 2rem;
  padding-left: 2rem;
  text-align: center;
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  justify-self: start;
  margin-bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  border-radius: 36px;
  border: 2px solid white;

  transition: 0.6s;
  overflow: hidden;
  &:focus {
    outline: 0;
  }
  &:hover {
    background: rgb(255, 255, 255);
    color: black;

    cursor: pointer;
  }
`
const LearnMore = styled(StyledLearnMore)`
  justify-self: start;
  margin-bottom: 0;
`

const BackgroundSection = styled.section`
  width: 100%;
  background: black;
`

const CardContainer = styled.div`
  width: 85%;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  grid-row-gap: 1rem;

  @media (min-width: 750px) {
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    grid-row-gap: 2rem;
    column-gap: 2rem;
    height: 100%;
  }

  @media (min-width: 1200px) {
    width: 100%;
  }
`

const CardGroupsOf2 = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  grid-row-gap: 1rem;

  @media (min-width: 750px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
    height: 100%;
  }
`

interface Props {
  color: any
}

const CardBackground = styled.div<Props>`
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;

  height: 100%;
  border: 2px solid ${props => props.color};

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 5rem 1fr min-content;
  row-gap: 1rem;

  @media (min-width: 200px) {
    padding: 1rem;
  }

  @media (min-width: 750px) {
    padding: 2rem;
  }
`

const StyledSectionShortWidth = styled.section`
  position: relative;
  padding-bottom: 5rem;

  @media (min-width: 200px) {
    margin-top: 3rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 750px) {
    padding-top: 2rem;
    width: 100%;
    padding-right: 64px;
    padding-left: 64px;
  }
  @media (min-width: 1200px) {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 0;
    padding-right: 0;
  }
`

const StyledCardP = styled.p`
  color: #ffffff;
  font-size: 1rem;
  padding-bottom: 1rem;
`

const IconWrapper = styled.div``

const StyledH2 = styled.h2`
  color: white;
  font-weight: 400;
  line-height: 150%;
`
